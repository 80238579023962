import React from 'react';
import { Grid, Box, AspectRatio } from 'theme-ui';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const ModuleSingleVideo = ({
  image,
  alt,
  caption,
  backgroundColor,
  shadowColor,
  video,
  size,
}) => {
  let width;
  switch (size) {
    case 'small': {
      width = '4/ span 4';
      break;
    }
    case 'large': {
      width = '2 / -2';
      break;
    }
    default:
      width = '3/ span 6';
      break;
  }

  return (
    <Grid
      columns={['repeat(4, 1fr)', 'repeat(12, 1fr)']}
      gap={[3, 4]}
      sx={{ mx: [3, 'auto'], maxWidth: 240, mt: [0, 15] }}
    >
      <Grid
        columns={['repeat(4, 1fr)', 'repeat(10, 1fr)']}
        gap={[3, 4]}
        sx={{
          gridColumn: ['1/ span 4', '2/ -2'],
          backgroundColor: ['transparent', backgroundColor],
        }}
      >
        <Box
          sx={{
            gridColumn: ['1 / -1', width],
            boxShadow: '0 32px 64px 0 rgb(0 0 0 / 40%)',
            my: [5, 20],
          }}
        >
          <AspectRatio
            ratio={image.aspectRatio}
            sx={{
              boxShadow: '0 32px 64px 0 rgb(0 0 0 / 40%)',
              video: {
                width: '100%',
              },
            }}
          >
            <video poster={image.src} autoPlay loop>
              <source src={video} type="video/mp4" />
            </video>
          </AspectRatio>
        </Box>
        {/* <Box
          sx={{
            gridColumn: ['7/ span 2'],
            boxShadow: '0 32px 64px 0 rgb(0 0 0 / 40%)',
            my: 20,
          }}
        >
          <AspectRatio
            ratio={image.aspectRatio}
            sx={{
              boxShadow: `0 32px 64px 0 ${shadowColor}`,
              video: {
                width: '100%',
              },
            }}
          >
            <video poster={image.src} autoPlay loop>
              <source src={video} type="video/mp4" />
            </video>
          </AspectRatio>
        </Box> */}
      </Grid>
    </Grid>
  );
};

ModuleSingleVideo.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
};
export default ModuleSingleVideo;
