/** @jsx jsx */
import { Box, AspectRatio, jsx } from 'theme-ui';
import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import ProjectHero from '../components/ProjectHero';
import Footer from '../components/Footer';
import Header from '../components/Header';
import ProjectTeaser from '../components/ProjectTeaser';
import ModuleSingleImage from '../components/ModuleSingleImage';
import ModuleSingleVideo from '../components/ModuleSingleVideo';
import ModuleMultiImage from '../components/ModuleMultiImage';
import ModuleQuote from '../components/ModuleQuote';
import Layout from '../global/Layout';

const Project = ({ data, pageContext }) => {
  const {
    backgroundColor: { hex: backgroundColor },
  } = data.sanityProject;

  const { next } = data;

  return (
    <Layout>
      <Box
        sx={{
          flexBasis: '100%',
          flexGrow: 1,
          paddingBottom: [10],
        }}
      >
        <Helmet>
          <title titleTemplate={`%s - ${data.site.siteMetadata.title}`}>
            {data.sanityProject.title}
          </title>
          <meta
            property="og:title"
            content={`${data.sanityProject.title} - ${data.site.siteMetadata.title}`}
          />
          <meta
            property="og:description"
            content={`${data.sanityProject.title} - ${data.sanityProject.category}`}
          />
          <meta
            property="description"
            content={`${data.sanityProject.title} - ${data.sanityProject.category}`}
          />
          <meta
            property="og:image"
            content={data.sanityProject.mainImage.asset.fluid.src}
          />
        </Helmet>

        <ProjectHero data={data.sanityProject} />

        {data.sanityProject.pageContent.map((module) => {
          switch (module._type) {
            case 'moduleSingleImage': {
              return (
                <ModuleSingleImage
                  image={module.mainImage.asset.fluid}
                  caption={module.mainImage.caption}
                  alt={module.mainImage.alt}
                />
              );
            }

            case 'moduleMultiImage': {
              return (
                <ModuleMultiImage
                  title={module.title}
                  copy={module._rawCopy}
                  mainColor={backgroundColor}
                  bottomLeftImage={module.bottomLeftImage.asset.fluid}
                  topLeftImage={module.topLeftImage.asset.fluid}
                  topRightImage={module.topRightImage.asset.fluid}
                />
              );
            }
            case 'moduleQuote': {
              return (
                <ModuleQuote
                  copy={module._rawCopy}
                  mainColor={backgroundColor}
                />
              );
            }
            case 'moduleSingleVideo': {
              const {
                backgroundColor: { hex: backgroundColor },
                shadowColor: { hex: shadowColor },
                mainImage: {
                  asset: { fluid: image },
                  alt,
                  caption,
                },
                mainVideo: {
                  asset: { url: video },
                },
                size,
              } = module;

              return (
                <>
                  <ModuleSingleVideo
                    {...{
                      size,
                      backgroundColor,
                      shadowColor,
                      image,
                      video,
                      alt,
                      caption,
                    }}
                  />
                </>
              );
            }
            default:
              return <p>template missing</p>;
          }
        })}
      </Box>

      <Footer nextProject={{ next }} />
    </Layout>
  );
};

export default Project;

export const query = graphql`
  query($id: String!, $nextId: String!) {
    site {
      siteMetadata {
        title
      }
    }
    next: sanityProject(id: { eq: $nextId }) {
      title
      slug {
        current
      }
      backgroundColor {
        hex
      }
      nextImage {
        asset {
          fluid(maxWidth: 840) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    sanityProject(id: { eq: $id }) {
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: { maxDepth: 10 })
      excerpt {
        _rawChildren(resolveReferences: { maxDepth: 10 })
      }

      backgroundColor {
        hex
      }
      schlingelingLeftColor {
        hex
      }
      schlingelingRightColor {
        hex
      }
      titleColor {
        hex
      }

      category

      featuredImage {
        asset {
          fluid(maxWidth: 840) {
            ...GatsbySanityImageFluid
          }
        }
      }
      mainImage {
        asset {
          fluid(maxWidth: 840) {
            ...GatsbySanityImageFluid
          }
        }
      }
      pageContent {
        ... on SanityModuleQuote {
          _key
          _type
          _rawCopy(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityModuleSingleVideo {
          _key
          _type
          size
          backgroundColor {
            hex
          }
          mainImage {
            asset {
              fluid(maxWidth: 499) {
                ...GatsbySanityImageFluid
              }
            }
          }
          mainVideo {
            asset {
              url
            }
          }
          shadowColor {
            hex
          }
        }
        ... on SanityModuleMultiImage {
          _key
          _type
          bottomLeftImage {
            asset {
              fluid(maxWidth: 1240) {
                ...GatsbySanityImageFluid
              }
            }
          }
          topLeftImage {
            asset {
              fluid(maxWidth: 1240) {
                ...GatsbySanityImageFluid
              }
            }
          }
          topRightImage {
            asset {
              fluid(maxWidth: 1240) {
                ...GatsbySanityImageFluid
              }
            }
          }
          title
          _rawCopy(resolveReferences: { maxDepth: 10 })
        }
        ... on SanityModuleSingleImage {
          _key
          _type
          mainImage {
            caption
            alt
            asset {
              fluid(maxWidth: 2560) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`;
