import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Flex } from 'theme-ui';
import Img from 'gatsby-image';
import Richtext from '../Richtext';
import Heading from '../Heading';

const ModuleMultiImage = ({
  copy,
  title,
  bottomLeftImage,
  topLeftImage,
  topRightImage,
  mainColor,
}) => {
  return (
    <Grid
      sx={{
        mt: [5, 15],
        maxWidth: 240,
        mx: [3, 'auto'],
      }}
      columns={['1fr', 'repeat(12, 1fr)']}
      gap={[3, 4]}
    >
      <Box
        sx={{
          display: ['none', 'block'],
          gridColumn: ['1/ span 4', '2/ span 5'],
        }}
      >
        <Img fluid={topLeftImage} />
        {/* <img src={topLeftImage} style={{ width: '100%' }} /> */}
        <Box sx={{ height: 4 }} />
        <Img fluid={bottomLeftImage} />
        {/* <img src={bottomLeftImage} style={{ width: '100%' }} /> */}
      </Box>

      <Box
        sx={{
          minWidth: '100%',
          alignItems: 'center',
          overflowY: 'hidden',
          display: ['flex', 'none'],
          scrollSnapType: 'x mandatory ',
          // position: 'relative',
        }}
      >
        <Box
          sx={{
            minWidth: 'calc(100vw - 2 * 16px)',
            mx: 2,
            scrollSnapAlign: 'center',
          }}
        >
          <Img fluid={topLeftImage} />
        </Box>
        <Box
          sx={{
            minWidth: 'calc(100vw - 2 * 16px)',
            mx: 2,
            scrollSnapAlign: 'center',
          }}
        >
          <Img fluid={bottomLeftImage} />
        </Box>
        <Box
          sx={{
            minWidth: 'calc(100vw - 2 * 16px)',
            mx: 2,
            scrollSnapAlign: 'center',
          }}
        >
          <Img fluid={topRightImage} />
        </Box>
      </Box>

      <Box
        sx={{
          gridColumn: ['1/-1', '7/ span 5'],
          pt: [0, 30],
        }}
      >
        <Box sx={{ display: ['none', 'block'] }}>
          <Img fluid={topRightImage} />
        </Box>
        {/* <img src={topRightImage} style={{ width: '100%' }} /> */}
        <Grid columns={['1fr', 'repeat(5, 1fr)']}>
          <Box sx={{ gridColumn: ['1/-1', '2/span 4'], mt: [5, 15] }}>
            <Heading as="h3" variant="h2" color={mainColor} sx={{ mb: [1, 2] }}>
              {title}
            </Heading>
            <Richtext content={copy} />
          </Box>
        </Grid>
        {/* <Richtext content={copy} /> */}
      </Box>
    </Grid>
  );
};

ModuleMultiImage.propTypes = {};

export default ModuleMultiImage;
