import React from 'react';
import { Grid, Box } from 'theme-ui';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const ModuleSingleImage = ({ image, alt, caption }) => {
  return (
    <Grid
      columns={['repeat(4, 1fr)', 'repeat(12, 1fr)']}
      gap={[3, 4]}
      sx={{ mx: [3, 'auto'], maxWidth: 240, mt: [5, 15], }}
    >
      <Box sx={{ gridColumn: ['1/ span 4', '2/ span 10'] }}>
        <Img fluid={image} alt={alt} title={caption} />
        {/* <img src={image} alt={alt} title={caption} style={{ width: '100%' }} /> */}
      </Box>
    </Grid>
  );
};

ModuleSingleImage.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
};
export default ModuleSingleImage;
