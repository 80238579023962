import React from 'react';
import { Grid, Box, Flex } from 'theme-ui';
import PropTypes from 'prop-types';
import Richtext from '../Richtext';
import Schlingeling from '../Schlingeling';

const ModuleQuote = ({ copy, mainColor }) => {
  return (
    <Grid
      columns={['repeat(4, 1fr)', 'repeat(12, 1fr)']}
      gap={[3, 4]}
      sx={{
        mt: [5, 15],
        mb: [6, 15],
        maxWidth: 240,
        mx: [3, 'auto'],
      }}
    >
      <Box sx={{ gridColumn: ['1/ span 4', '3/ span 8'] }}>
        <Richtext content={copy} variant="text.quote" />
        <Flex
          sx={{
            justifyContent: 'center',
          }}
        >
          <Schlingeling leftColor={mainColor} rightColor={mainColor} />
        </Flex>
      </Box>
    </Grid>
  );
};
ModuleQuote.propTypes = {
  copy: PropTypes.object,
  mainColor: PropTypes.string.isRequired,
};
export default ModuleQuote;
